/* .modal-backdrop.show {
    opacity: 0;
} */
/* .modal {
    border:1px solid red;
} */
.modal-content{
    
    border-radius: 100px 0px 100px 100px !important;
}
.modal-backdrop.fade{
    opacity: 0;
}

.modal-backdrop{
    display: none;
    /* opacity: 1; */
}
.menuClass{
    overflow: visible !important;
}
.textOffer{
    font-weight: 400;
    font-size: 12px;
    color:#000;
}
.HeaderText{
animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}




/* Spiner looding bar */
  
  
  .progressBarValue {
    height: 100%;
    background: linear-gradient(90deg, #6352e8, #887ce5);
    border-radius: 10px;    
    position: relative;
    z-index: 9;
    top: 0;
    transition: width 0.3s ease-out; /* Slightly reduced duration for smoother effect */
    will-change: width; /* Optimized for smoother rendering */
  }
  
  .glowBar {
    width: 30px;
    height: 30px;
    background: radial-gradient(circle, #fff, rgba(255, 255, 255, 0.1));
    position: absolute;
    top: -5px;
    right: 0;
    border-radius: 50%;
    box-shadow: 0 0 10px #66c5ff;
    animation: glow-move 0.5s infinite linear;
  }
  
  @keyframes glow-move {
    0% {
      left: 0;
    }
    100% {
      left: 100%;
      transform: translateX(-100%);
    }
  }
  